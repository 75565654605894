import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "homeChunk" */ "@/views/Home/Home.vue"),
  },
  {
    path: "/services",
    name: "services",
    component: () => import(/* webpackChunkName: "servicesChunk" */ "@/views/Services/Services.vue"),
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import(/* webpackChunkName: "aboutChunk" */ "@/views/About/About.vue"),
  },
  {
    path: "/our-approach",
    name: "our-approach",
    component: () => import(/* webpackChunkName: "ourApproachChunk" */ "@/views/OurApproach/OurApproach.vue"),
  },
  {
    path: "/career",
    name: "career",
    component: () => import(/* webpackChunkName: "careerChunk" */ "@/views/Career/Career.vue"),
  },
  {
    path: "/career/:id",
    name: "career-detail",
    component: () => import(/* webpackChunkName: "careerDetailChunk" */ "@/views/CareerDetail/CareerDetail.vue"),
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: () => import(/* webpackChunkName: "contactChunk" */ "@/views/Contact/Contact.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.getElementById("app")?.scrollIntoView();
  next();
});

export default router;
