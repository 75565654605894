<template>
  <div id="app" class="fixed-navbar">
    <b-navbar id="nav-nstra" class="nstra-container bg-white fixed-top" toggleable="lg" variant="light">
      <b-navbar-brand href="#" :to="'/'">
        <b-img class="navbar-brand-img" src="./assets/images/logo-primary@2x.png" alt="Nostratech Logo"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" @click="handleNav">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="x"></b-icon>
          <b-icon v-else icon="list"></b-icon>
        </template>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#" :to="'/about-us'" :class="{ active: currentPage('about-us') }">
            About Us
          </b-nav-item>
          <b-nav-item href="#" :to="'/our-approach'" :class="{ active: currentPage('our-approach') }">
            Our Approach
          </b-nav-item>
          <b-nav-item href="#" :to="'/services'" :class="{ active: currentPage('services') }">
            Services
          </b-nav-item>
          <b-nav-item href="#" :to="'/career'" :class="{ active: currentPage('career') }">
            Career
          </b-nav-item>
          <b-nav-item href="#" :to="'/contact-us'" class="d-sm-block d-lg-none">
            Contact Us
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto d-none d-lg-block">
          <b-button class="contact-us-btn justify-content-center d-flex align-items-center px-20px" variant="primary" :to="'/contact-us'">
            <span class="d-block mr-1">Contact Us</span>
            <feather type="arrow-right" size="18"></feather>
          </b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <transition name="slide-fade" mode="out-in">
      <router-view />
    </transition>
    <div class="footer-wrap" :class="{ 'bg-monochrome-grey-super-light-grey': currentPage('about-us') }">
      <div v-scrollanimation="'up'" class="nstra-container floating-footer" :class="{ 'd-none': currentPage('career') || currentPage('contact-us') }">
        <div class="floating-footer-content bg-blue-teal text-center reguler-shadow">
          <img class="dots-cta" src="@/assets/images/dots-cta.png" alt="dots-cta" />
          <h3 class="floating-footer-question">Ready to grow with us?</h3>
          <!-- <p class="floating-footer-text">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> -->
          <b-button class="contact-us-btn d-flex justify-content-center align-items-center mx-auto" variant="primary" :to="'contact-us'">
            <span class="d-block mr-1">Contact Us</span>
            <feather type="arrow-right" size="18"></feather>
          </b-button>
        </div>
      </div>
      <footer class="nstra-container footer bg-dark-green">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col md="5">
                <div class="footer-content-title">Company</div>
                <b-row class="footer-content" cols="1" cols-md="2">
                  <b-col>
                    <router-link :to="'/about-us'">About Us</router-link>
                  </b-col>
                  <b-col>
                    <router-link :to="'/career'">Career</router-link>
                  </b-col>
                  <b-col>
                    <router-link :to="'/services'">Services</router-link>
                  </b-col>
                  <b-col>
                    <router-link :to="'/contact-us'">Contact Us</router-link>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="5">
                <div class="footer-content-title">Office</div>
                <div class="footer-content">
                  <p>
                    Sequis Center 10<sup>th</sup> Floor. 
                    <br />
                    Jl. Jend. Sudirman No. 71, Senayan, Kebayoran Baru, Jakarta Selatan 12190, DKI Jakarta, Indonesia.
                  </p>
                </div>
              </b-col>
              <b-col md="2">
                <div class="footer-content-title">Follow Us</div>
                <div class="footer-content">
                  <ul class="list-social">
                    <li>
                      <a href="https://www.linkedin.com/company/nostratech" target="_blank" rel="noreferrer noopener">
                        <i class="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://instagram.com/nostratechstory?igshid=r9ztw6p5n8pp" target="_blank" rel="noreferrer noopener">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="copyright">
            <span>
              &copy; 2021 Nostra Technology. All Right Reserved
            </span>
          </b-col>
        </b-row>
      </footer>
    </div>
  </div>
</template>

<script>
import { BNavbar, BNavbarBrand, BImg, BNavbarToggle, BNavbarNav, BNavItem, BCollapse, BIcon, BButton, BRow, BCol } from "bootstrap-vue";
export default {
  name: "App",
  components: {
    BNavbar,
    BNavbarBrand,
    BImg,
    BNavbarToggle,
    BNavbarNav,
    BNavItem,
    BCollapse,
    BIcon,
    BButton,
    BRow,
    BCol,
  },
  data: function() {
    return {
      scrollValue: 0,
      lastScrollPosition: 0,
    };
  },
  mounted() {
    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", this.onScroll, true);
  },
  methods: {
    currentPage(path) {
      return this.$route.path.includes(path);
    },
    onScroll: function(e) {
      this.scrollValue = e.srcElement.scrollTop;
    },
    handleNav() {
      var el_app = document.getElementById("app");
      var el_nav = document.getElementById("nav-nstra");
      el_nav.classList.add("fixed-top");
      el_app.classList.add("fixed-navbar");
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";

// Vue Slick Carousel
@import "~vue-slick-carousel/dist/vue-slick-carousel.css";
@import "~vue-slick-carousel/dist/vue-slick-carousel-theme.css";

@import "assets/styles/index";
</style>
