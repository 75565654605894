  export default {
    bind(el, binding) {
    const animatedScrollObserver = new IntersectionObserver(
            (entries, animatedScrollObserver) => {
                entries.forEach((entry) => {
                    if(entry.isIntersecting) {
                        entry.target.classList.add(`enter-${binding.value}`);
                        animatedScrollObserver.unobserve(entry.target);
                    }
                });
            }
        );
      el.classList.add(`before-enter-${binding.value}`);
      animatedScrollObserver.observe(el);
    }
  }