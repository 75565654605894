import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import VueFeather from "vue-feather";
import { IconsPlugin } from 'bootstrap-vue'

// Custom Directives
import ScrollAnimation from "./directives/animateOnScroll.js";

Vue.directive("scrollanimation", ScrollAnimation);
Vue.use(VueFeather);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
